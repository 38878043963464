// liveStreamConstants.js
'use strict';

export const STATUS_ONLINE = 'online';
export const STATUS_OFFLINE = 'offline';

export const PRESET_PREVIEW = 'preview';
export const PRESET_SD = 'sd';

export const LIVESTREAM_PATH = 'livestream';

export const CHARGE_PERIOD_SECONDS = 60;
// TODO: change to LEAD_CHARGE_SECONDS
export const PRECHARGE_SECONDS = 5;
export const TEMPORARY_LEAVE_SECONDS = 180;
export const SHOW_REMAINING_SECONDS_BALANCE_MULTIPLE = 3;

export const CHAT_INPUT_LIMITATION = 56;
export const CHAT_QUEUE_LIMITATION = 5;
export const CHAT_DEBOUNCE_SECONDS = 1;
export const PREVIOUS_CHATS_LIMITATION = 2;
export const PINNED_MESSAGE_LIMITATION = 50;

export const SHOW_LIVE_STREAM_AD_THRESHOLD = 3;

export const TOP_LEADERBOARD_USER_LEAST = 15;
export const GIFT_LEADERBOARD_USER_LEAST = 15;
export const PRIVATE_SHOW_LEADERBOARD_MINIMUM_USER_AMOUNT = 15;

export const RANGE_SESSION = 'session';
export const RANGE_24H = '1';
export const RANGE_7D = '7';
export const RANGE_30D = '30';

export const ASIDE_WIDTH = 360;

export const liveStreamChatType = {
  GIFT: 'gift',
  KARAOKE: 'karaoke',
  USER_ENTERED: 'userEntered',
  CHAT: 'chat',
  TICKET: 'ticket',
  EARLY_BIRD_TICKET: 'earlyBirdTicket',
  TOY_DEVICE: 'toyDevice',
};

export const livestreamTicketType = {
  EARLY_BIRD: 'earlybird',
  SHOW: 'show',
};

export const liveStreamSessionLeaderboardCategory = {
  GIFTS: 'gifts',
  VIEWS: 'views',
  TOTAL: 'total',
};

export const privateShowStatus = {
  FUNDING: 'funding',
  FUNDING_FINAL_CALL: 'funding-final-call',
  CONSIDERING: 'considering',
  REFUNDING: 'refunding',
  PREPARING: 'preparing',
  PERFORMING: 'performing',
};

export const privateShowGiftType = {
  SINGLE: 'single',
  MORE: 'more',
  MVP: 'mvp',
};

export const privateShowTimerType = {
  TO_FUNDING_FINAL_CALL: 'to-final-call',
  TO_CONSIDERING: 'to-considering',
  TO_REFUNDING: 'to-refunding',
  TO_IDLE: 'to-idle',
};

export const livestreamPaymentVia = {
  CLICK: 'click',
  AUTO: 'auto',
};

export const livestreamMode = {
  PRIVATE_STREAM: 'private',
  PRIVATE_SHOW: 'funding',
};

export const livestreamAdType = {
  SWAGGER: 'swagger',
  ADVERTISER: 'advertiser',
};

export const livestreamNoticeType = {
  USER_ENTERED_NOTICE: 'userEntered',
  GIFT_NOTICE: 'giftNotice',
  TICKET_NOTICE: 'ticketNotice',
};

export const SESSION_DIAMOND_FIELD = {
  'total.diamonds.gifts': {
    title: 'info_stream_gift_revenue',
  },
  'total.diamonds.karaoke': {
    title: 'info_stream_command_revenue',
  },
  'total.diamonds.views': {
    title: 'info_private_stream_revenue',
  },
  'total.diamonds.show': {
    title: 'info_stream_show_revenue',
  },
};

export const LivestreamProvider = {
  BYTEPLUS: 'byteplus',
  SWAG: 'swag',
};
